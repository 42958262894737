import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';
import { clsx } from 'clsx';

import styles from './successEventCreatedDialog.module.css';

const WIDTH = '300px';

export const SuccessEventCreatedDialog = ({
  show,
  onClose,
  className,
}) => {
  const classNames = { container: clsx(styles.container, className) };

  return (
    <Dialog
      show={ show }
      onClick={ onClose }
      showCloseButton
      width={ WIDTH }
    >
      <div className={ classNames.container }>
        <h2>Заявка успешно создана</h2>
      </div>
    </Dialog>
  );
};

SuccessEventCreatedDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
